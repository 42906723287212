<template>
  <div class="loan_item">
    <div class="inner inner1">
      <div class="content">
        <div class="left_cell">
          <h1>About Us</h1>
          <p class="inner1_text">Dolphin Lending Investor, Inc. is a leading financial technology company based in the Philippines, dedicated to providing innovative and accessible financial solutions to individuals across the country. Our flagship product, PesoGo, is a mobile lending application designed to offer quick and convenient loans to Filipinos, empowering them to meet their financial needs with ease and confidence.
          </p>
          <p class="inner1_text">
            At Dolphin Lending Investor, Inc., our mission is to bridge the financial gap for underserved communities in the Philippines by offering accessible and reliable lending solutions. We aim to support the financial well-being of our users by providing them with the resources they need to achieve their personal and professional goals.
          </p>
        </div>
        <div class="right_cell">
          <img src="~@/assets/images/loanImg/about_us.png">
        </div>
      </div>
    </div>
    <div class="inner inner5">
      <div class="inner5-bg">
        <h3>Values</h3>
      </div>
      <div class="inner5_item">
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/about_inner5-1.png" alt="">
            <div class="item_info">
              <h2>open</h2>
              <p>Respect and tolerance, empathy</p>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/about_inner5-2.png" alt="">
            <div class="item_info">
              <h2>cooperation</h2>
              <p>Collaborate with each other and continue to evolve</p>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/about_inner5-3.png" alt="">
            <div class="item_info">
              <h2>create</h2>
              <p>Beyond innovation, explore the future</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner2">
      <!-- <h4>Sobre nosotros</h4> -->
      <div class="content">
        <div class="left_cell">
          <h1>Mission</h1>
          <p>Our goal is to offer clients a flexible, simple, secure, fast and worry-free financial platform. We don't have many thresholds and just try to click our apply button to experience our service.</p>
        </div>
        <div class="right_cell">
          <img src="@/assets/images/loanImg/about_inner2.png" alt="">
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <div class="content">
        <div class="right_cell">
          <img src="@/assets/images/loanImg/about_inner3.png" alt="">
        </div>
        <div class="left_cell">
          <h1>Vision</h1>
          <p>To solve the problem of money use and improve the quality of life for everyone, we are committed to providing the best financial technology services to society.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    min-width: 1100px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 18px;
      color: #6E60F9;
    }
    .content{
      padding: 0px calc((100% - 1100px) / 2) 0;
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      .inner1_text{
        color: #333;
        line-height: 25px;
        margin: 40px 0;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #6E60F9;
      }
    }
  }
  .inner1{
    .content{
      display: flex;
      .left_cell,.right_cell{
        flex: 1;
        // display: flex;
        // justify-content: center;
      }
      .left_cell{
        padding: 75px 0;
      }
      .right_cell{
        flex: 1.5;
        img{
          margin: 0px auto 25px!important;
          width: 500px;
          height: 440px;
          display: block;
        }
      }
    }
  }
  .inner2{
    .content{
      display: flex;
      .left_cell,.right_cell{
        flex: 1;
        display: flex;
      }
      h1{
        font-size: 30px;
      }
      img{
        margin: 25px auto;
        width: 300px;
        height: 300px;
        // display: block;
      }
      .left_cell{
        display: flex;
        flex-direction: column;
        p{
          font-size: 12px;
          line-height: 25px;
          color: black;
          padding: 0 170px 0px 0;
        }
      }
      .right_cell{
        display: flex;
        justify-content: right;
      }
    }
  }

  .inner3{
    padding: 20px 0;
    background: #F8F9FF;
    .content{
      display: flex;
      .left_cell,.right_cell{
        flex: 1;
        display: flex;
        justify-content: center;
      }
      img{
        margin: 25px 0;
        width: 300px;
        height: 300px;
        // display: block;
      }
      .left_cell{
        flex: 1.5;
        display: flex;
        flex-direction: column;
        margin-left: 390px;
        p{
          font-size: 12px;
          line-height: 27px;
          color: black;
        }
      }
    }
  }

  .inner5{
    padding: 0 0 70px 0;
    .inner5-bg{
      width: 100%;
      height: 300px;
      background: linear-gradient(271deg, #9D409B 0%, #160033 100%);
      // padding: 50px 250px;
    }
    h2{
      line-height: 30px;
      margin-bottom: 20px;
    }
    h3{
      font-size: 40px;
      color: white;
      text-align: center;
      line-height:200px;
    }
    .inner5_item{
      // width: 100%;
      display: flex;
      justify-content: space-between;
      margin: -100px 0 0;
      padding: 0px calc((100% - 1200px) / 2) 0;
      font-size: 14px;
      text-align: center;
      line-height: 16px;
      .content{
        flex: 1;
        background: #fff;
        padding: 40px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 60px;
        box-shadow: 0 0 5px #999;
        img{
          // margin-top: 50px;
          width: 100px;
        }
      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    .inner{
      min-width: calc(100vw) !important;
    }
    .inner1{
      h4{
        padding: 0 20px;
      }
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          padding: 20px 0;
        }
        .cell_item{
          width: 100% !important;
          height: 100px;
        }
        img{
          margin-top: 0px;
          width: 300px !important;
          height: 300px !important;
          margin: 0 auto ;
        }
      }
    }
    .inner2{
      h4{
        padding: 0 20px;
      }
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          p{
            padding: 0 !important;
            width: 100% !important;
            margin:  15px 0;
          }
        }
        img{
          margin: -20px 0px 50px;
        }
      }
    }
    .inner3{
      padding-top: 20px;
      h4{
        padding: 0 20px;
      }
      .content{
        padding: 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          margin-left: 0px;
          padding: 0;
          p{
            padding: 0 !important;
            width: 100% !important;
            margin:  15px 0;

          }
        }
        img{
          margin: -20px 0px 50px;
        }
      }
    }
  }
  .inner5{
    padding: 0 0 100px 0  !important;
    min-width: calc(100vw - 40px) !important;
    width: calc(100vw - 40px) !important;
    margin: 0 auto !important;
    h3{
      font-size: 18px;
      line-height: 120px !important;
    }
    .inner5-bg{
      height: 200px !important;
    }
    .inner5_item{
      margin:  -100px 0px !important;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .content{
        margin: 20px 25px !important;
      }
    }
  }
}
</style>
